<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="card">
      <div class="row header_row">
        <div class="col-sm-10">
          <h5 v-if="mode === 'add'" class="title">Dodaj nową stawkę</h5>
          <h5 v-if="mode === 'edit'" class="title">Edytuj stawkę</h5>
          <h5 v-if="mode === 'date'" class="title">Następny zakres</h5>
        </div>
        <div>
          <i v-on:click="$emit('close')" class="fa fa-2x fa-times-circle-o" style="cursor: pointer; opacity: 0.5;"
             aria-hidden="true"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <el-form label-position="left" label-width="150px">
            <el-form-item label="Klient">
              <fg-input :error="getError('client_id')" data-vv-as="Klient">
                <el-select
                  v-model="model.client_id"
                  v-validate="modelValidations.client_id"
                  name="client_id"
                  filterable
                  :disabled="mode !== 'add'"
                >
                  <el-option
                    v-for="client in clients"
                    :label="'[' + client.client_id + '] ' + client.client_name"
                    :value="client.client_id"
                    :key="client.client_id"
                  ></el-option>
                </el-select>
              </fg-input>
            </el-form-item>

            <el-form-item label="Obowiązuje ods">
              <el-date-picker
                v-model="model.day"
                type="date"
                align="center"
                :clearable="false"
                format="yyyy-MM-dd"
                :disabled="mode === 'date'"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>

            <p class="text-danger text-md-right" v-if="mode === 'date' && !dateCheck"><small>Nowa data musi być
              późniejsza od istniejącej</small>
              <p/>

              <el-form-item label="Stawka">
                <el-input-number
                  name="rate"
                  v-model="model.rate"
                  v-validate="modelValidations.rate"
                  :error="getError('rate')"
                  :precision="2"
                  :step="0.1"
                  controls-position="right"
                ></el-input-number>
              </el-form-item>

              <el-form-item label="Współczynnik">
                <el-input-number
                  name="ratio"
                  v-model="model.ratio"
                  v-validate="modelValidations.ratio"
                  :error="getError('ratio')"
                  :precision="2"
                  :step="0.1"
                  controls-position="right"
                ></el-input-number>
              </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="row">
        <p-button type="success" @click.prevent="saveData">Zapisz</p-button>
      </div>

    </div>
  </div>
</template>

<script>
import moment from "moment"; // time manipulation library
import utils from "src/utils";
// ui components
import {Input, Select, Option, Tooltip, InputNumber, DatePicker, Form, FormItem} from "element-ui";

// vuex actions
import {SAVE_CLIENT_RATE, CLIENT_RATES_INVALID} from "src/store/actions/secure/client_rates";
import {UNIQUE_CLIENTS_INVALID} from "src/store/actions/secure/unique_clients";

// event bus
import {eventBus} from "src/main";

import * as priv from 'src/privileges.js';
import * as regexp from "src/regexp.js";

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [InputNumber.name]: InputNumber,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },
  props: ['mode', 'row'],
  data() {
    return {
      loading: false,
      date_from: '',
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: 'Today',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: 'Yesterday',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: 'A week ago',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
      model: {
        id: null,
        client_id: null,
        client_name: null,
        day: moment(utils.today()).format("YYYY-MM-DD"),
        rate: 0,
        ratio: 0
      },
      modelValidations: {
        id: {
          numeric: true,
          min: 1
        },
        client_id: {
          required: true,
          numeric: true,
          min: 1
        },
        rate: {
          required: true
        },
        ratio: {
          required: true
        },
      },
    }
  },
  computed: {
    uniqueClients() {
      return this.$store.getters.getUniqueClients;
    },
    allClients() {
      return this.$store.getters.getClients;
    },
    clients() {
      if (this.mode !== 'add') {
        return this.allClients;
      } else {
        return this.uniqueClients;
      }
    },
    dateCheck() {
      if(this.mode === 'date' && this.date_from !== '') {
        let existingDate = new Date(this.date_from);
        let selectedDate = new Date(this.model.day);

        if (existingDate.getTime() < selectedDate.getTime()) {
          return true;
        } else {
          return false;
        }
      } else return false;
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async saveData() {
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        if (this.mode === 'date' && !this.dateCheck) {
          eventBus.$emit("notify", {
            message: "Nowa data musi być późniejsza od istniejącej!",
            type: "warning"
          });
          return;
        }
        let model = this.model;
        model.day = moment(this.model.day).format("YYYY-MM-DD");
        model.mode = this.mode;

        await this.$store.dispatch(SAVE_CLIENT_RATE, model).catch(error => {
          console.log(error.response)
          eventBus.$emit("notify", {
            message: "Błąd zapisu danych!",
            type: "warning"
          });
        })

        if (this.$store.getters.getClientRatesModifiedRecords === true) {
          eventBus.$emit("notify", {
            message: "Rekord został " + (this.mode === 'edit' ? "zmodyfikowany" : "dodany"),
            type: "success"
          });

          this.$store.commit(CLIENT_RATES_INVALID) // oznacz nieaktualność danych
          this.$store.commit(UNIQUE_CLIENTS_INVALID);
          eventBus.$emit('clientRatesSaved', this.day);
          this.$emit('close');
        }
      }
    },
  },
  async created() {
    if (this.mode !== 'add') {
      console.log(this.mode);
      if (this.mode === 'edit') {
        this.model = {
          id: this.row.id,
          client_id: this.row.client_id,
          client_name: this.row.client_name,
          rate: this.row.rate,
          ratio: this.row.ratio,
          day: new Date(),
        };

        this.model.day = new Date(this.row.date_from);
      } else {
        this.model = {
          id: this.row.id,
          client_id: this.row.client_id,
          client_name: this.row.client_name,
          rate: this.row.rate,
          ratio: this.row.ratio,
        };
        this.model.day = moment(utils.today()).format("YYYY-MM-DD");
      }

      this.date_from = this.row.date_from;
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 10px;
}

.card {
  margin: 0;
  display: flex;
}

.row:last-child {
  justify-content: center;
}

.center {
  justify-content: center;
  align-content: center;
}

.header_row {
  display: flex;
  justify-content: space-between;
}
</style>
